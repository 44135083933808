import React, { FC, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react'

import { GetWrappedIcon } from '../../../../../components/Icons'
import MaskedInput from '../../../../../components/Form/MaskedInput'

import { useRootStore } from '../../../../../services/RootStoreContext'

import Organization from '../../../../../Model/Organization'
import useFormContext from '../../../../../components/Form/FormContext'

const OrgAccounts: FC<{ orgData: Organization }> = (): React.JSX.Element => {
  const {
    handleChange,
    handleBlur,
    isFieldTouched,
    getError,
    setFieldValue,
    model: orgData,
  } = useFormContext()
  const {
    orgStore: {
      currentOrg: { externalCRMProvider, externalCRMProviderDisplayName },
    },
  } = useRootStore()
  const [newInvestmentAccountNumber, setNewInvestmentAccountNumber] =
    useState('')

  const getInvestmentSection = () => {
    if (orgData) {
      return (
        <Stack spacing={3}>
          <Box>
            <Typography>Current Investment Account Numbers</Typography>
            {orgData.accounts.map((number: string) => (
              <Stack
                direction='row'
                key={number}
                spacing={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '1rem',
                }}
              >
                <GetWrappedIcon name='bank' />
                <Typography>{number}</Typography>
                <Tooltip title={<Typography>Remove Account</Typography>}>
                  <Link
                    color='error'
                    onClick={() => {
                      setFieldValue(
                        'accounts',
                        orgData.accounts.filter((acc: string) => acc !== number)
                      )
                    }}
                  >
                    <GetWrappedIcon name='delete' />
                  </Link>
                </Tooltip>
              </Stack>
            ))}
          </Box>
          <FormControl>
            <TextField
              fullWidth
              label='New Investment Account Number'
              onChange={({
                target: { value },
              }: {
                target: { value: string | number }
              }) => {
                setNewInvestmentAccountNumber(value.toString().trim())
              }}
              value={newInvestmentAccountNumber}
            />
          </FormControl>
          <Button
            color='success'
            disabled={newInvestmentAccountNumber.length === 0}
            onClick={() => {
              if (newInvestmentAccountNumber.length > 0) {
                if (!orgData.accounts.includes(newInvestmentAccountNumber)) {
                  orgData.accounts.push(newInvestmentAccountNumber)
                }

                setNewInvestmentAccountNumber('')
              }
            }}
            startIcon={<GetWrappedIcon name='bank' />}
            variant='contained'
          >
            Add Account
          </Button>
        </Stack>
      )
    }
    return null
  }
  return (
    <Stack spacing={3}>
      <FormControl>
        <TextField
          aria-describedby='stripe-helper-text'
          fullWidth
          label='Stripe Customer ID'
          placeholder='Stripe Customer ID'
          name='stripe_customer_id'
          value={orgData.stripe_customer_id}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(
            isFieldTouched('stripe_customer_id') &&
              getError('stripe_customer_id')
          )}
          helperText={
            isFieldTouched('stripe_customer_id') &&
            getError('stripe_customer_id')
          }
        />
        <FormHelperText id='stripe-helper-text'>
          Example: cus_I0IX9npTRg7APO
        </FormHelperText>
      </FormControl>
      <FormControl>
        <TextField
          aria-describedby='external-contact-id-helper-text'
          data-qa='org-profile-input-external-contact-id'
          fullWidth
          label='CRM ID'
          onChange={handleChange}
          onBlur={handleBlur}
          name='external_contact_id'
          placeholder='CRM ID'
          value={orgData.external_contact_id}
          error={Boolean(
            isFieldTouched('external_contact_id') &&
              getError('external_contact_id')
          )}
          helperText={
            isFieldTouched('external_contact_id') &&
            getError('external_contact_id')
          }
        />
        {externalCRMProvider && (
          <FormHelperText id='external-contact-id-helper-text'>
            This is the ID from {externalCRMProviderDisplayName}
          </FormHelperText>
        )}
      </FormControl>
      {getInvestmentSection()}
    </Stack>
  )
}

export default observer(OrgAccounts)
